import React, { CSSProperties, FC, Fragment, useContext, useEffect, useState } from 'react'
import Typist from 'react-typist'
import Card from '../components/Card'
import Layout, { BUTTON_TYPE_KEY } from '../components/layout'
import { ButtonType } from '../components/MatrixButton/index.models'
import Seo from '../components/seo'
import '../css/index.css'

const IMAGES_DATA = 'IMAGES_DATA'

interface IMainPageProps {}

const hex2rgb = (hex, opacity) => {
    if (!hex) return hex
    const r = parseInt(hex.slice(1, 3), 16)
    const g = parseInt(hex.slice(3, 5), 16)
    const b = parseInt(hex.slice(5, 7), 16)
    // return {r, g, b} // return an object
    return `rgba(${r}, ${g}, ${b}, ${opacity})`
}

const linkStyles: CSSProperties = {
    display: 'inline-flex',
    flexDirection: 'column',
    color: '#09ec4d',
    textDecoration: 'none'
}

interface ICards {
    title: string
    skills: string[]
}

const CARDS: ICards[] = [
    {
        title: 'Backend Skills',
        skills: ['SQL', 'PHP', 'MYSQL', 'NodeJs', 'Firebase', 'ExpressJs', 'Mongo DB', 'Apache Servers', 'Headless CMS']
    },
    {
        title: 'Frontend Skills',
        skills: [
            'CSS3',
            'HTML5',
            'TypeScript',
            'JavaScript',
            'WordPress',
            'NextJs | Gatsby',
            'React | ReactJs',
            'Angular | AngularJs',
            'Styled Components',
            'Storybook Development',
            'State Management | Redux'
        ]
    },
    {
        title: 'Essential Skills',
        skills: [
            'Git',
            'Design',
            'Graphql',
            'Mentoring',
            'Debugging',
            'Best Practices',
            'Problem Solving',
            'UX understanding',
            'Agile Development',
            'Responsive Development',
            'Works well in a collaborative environment'
        ]
    }
]

const MainPage: FC<IMainPageProps> = ({}) => {
    const [showBottom, setShowBottom] = useState(false)
    const [imageData, setImageData] = useState<any>(null)
    const [showRabbitText, setShowRabbitText] = useState<any>(null)

    useEffect(() => {
        if (typeof window !== 'undefined') {
            // 👉️ can use localStorage here
            const data: any = window.localStorage.getItem(BUTTON_TYPE_KEY) || null
            const imageData = window.localStorage.getItem(IMAGES_DATA) || null
            if (imageData) {
                setImageData(JSON.parse(imageData))
            }

            if (data) {
                setShowRabbitText(JSON.parse(data))
            }
        }
    }, [])

    return (
        <Layout>
            <Seo
                title="Home | David R Tello Software Developer"
                description="My Name is David Tello, I am a Software engineer in San Diego, CA."
            />
            <section className="main-section">
                <Typist
                    cursor={{
                        show: false
                    }}
                    onTypingDone={() => setShowBottom(true)}
                    avgTypingDelay={40}
                >
                    <Typist.Delay ms={500} />
                    <h1
                        style={{
                            fontSize: 42,
                            marginBottom: '1rem'
                        }}
                    >
                        Hello and welcome,
                    </h1>
                    <Typist.Delay ms={1000} />
                    <p style={{ fontSize: 24, margin: 0 }}>
                        my name is{' '}
                        <a
                            style={linkStyles}
                            href={'https://www.linkedin.com/in/david-tello-95b3566a/'}
                            target="_blank"
                        >
                            David Tello
                            <span
                                style={{
                                    display: 'block',
                                    width: '100%',
                                    height: 3,
                                    background: `linear-gradient(90deg, rgba(9,236,77,0.7) 0%, ${imageData?.images?.avg_color} 100%)`
                                }}
                            />
                        </a>{' '}
                        I'm currently working{' '}
                        <span style={{ position: 'relative' }}>
                            <span>
                                [{' '}
                                <a style={linkStyles} href={'https://www.levitate.ai'} target="_blank">
                                    @Levitate
                                    <span
                                        style={{
                                            display: 'block',
                                            width: '100%',
                                            height: 3,
                                            background: `linear-gradient(90deg, rgba(9,236,77,0.7) 0%, ${imageData?.images?.avg_color} 100%)`
                                        }}
                                    />
                                </a>{' '}
                                ]
                            </span>
                        </span>
                        .
                    </p>
                    <p style={{ fontSize: 24, margin: 0 }}>
                        Please feel free to contact me{' '}
                        <a
                            style={linkStyles}
                            href={`mailto:hello.davidtello@gmail.com?subject=IMPORTANT WEBSITE CONTACT! ${new Date()}&body=Hello there`}
                            target="_blank"
                        >
                            here
                            <span
                                style={{
                                    display: 'block',
                                    width: '100%',
                                    height: 3,
                                    background: `linear-gradient(90deg, rgba(9,236,77,0.7) 0%, ${imageData?.images?.avg_color} 100%)`
                                }}
                            />
                        </a>
                        .
                    </p>
                </Typist>

                {Boolean(!showRabbitText) && (
                    <Typist
                        cursor={{
                            show: false
                        }}
                        avgTypingDelay={70}
                    >
                        <Typist.Delay ms={17000} />
                        <p style={{ fontSize: 24, marginBottom: '2.4rem' }}>Follow the white rabbit... 🐇</p>
                        <Typist.Backspace count={29} delay={3000} />
                    </Typist>
                )}
            </section>

            <article className="main-article">
                {CARDS.map((card, idx) => {
                    return (
                        <Card
                            key={`card-${idx}`}
                            showCard={showBottom}
                            index={idx}
                            backgroundColor={
                                showRabbitText?.selected === ButtonType.RED
                                    ? `linear-gradient(90deg, rgba(9, 236, 77, 0.3) 0%, ${hex2rgb(
                                          imageData?.images?.avg_color,
                                          0.1
                                      )} 50%)`
                                    : `linear-gradient(90deg, ${hex2rgb(
                                          imageData?.images?.avg_color,
                                          0.8
                                      )}  0%, rgba(0,0,0, 0.5) 120%)`
                            }
                        >
                            <h3>{card.title}</h3>
                            {showBottom && (
                                <Typist
                                    cursor={{
                                        show: false
                                    }}
                                    avgTypingDelay={40}
                                >
                                    <ul>
                                        {card.skills.map((skill, idx2) => {
                                            return showRabbitText?.selected === ButtonType.RED ? (
                                                <li key={`skill-${skill}-${idx}-${idx2}`}> &#128187; &nbsp; {skill}</li>
                                            ) : (
                                                <li key={`skill-${skill}-${idx}-${idx2}`}>&#128223; &nbsp; {skill}</li>
                                            )
                                        })}
                                    </ul>
                                </Typist>
                            )}
                        </Card>
                    )
                })}
            </article>
        </Layout>
    )
}

export default MainPage
